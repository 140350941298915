/**
 * @license
 * MyFonts Webfont Build ID 3643894, 2018-09-19T06:53:59-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: AvenirLTPro-Light by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir/pro-35-light/
 *
 * Webfont: AvenirLTPro-Book by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir/pro-45-book/
 *
 * Webfont: AvenirLTPro-Medium by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir/pro-65-medium/
 *
 * Webfont: AvenirLTPro-Heavy by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir/pro-85-heavy/
 *
 * Webfont: AvenirLTPro-Black by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir/pro-95-black/
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3643894
 * Licensed pageviews: 250,000
 * Webfonts copyright: Copyright &#x00A9; 2014 Monotype GmbH. All rights reserved.
 *
 * © 2018 MyFonts Inc
*/

@font-face {
  font-family: 'AvenirLTPro-Light';
  src: url('/assets/fonts/avenir/3799F6_0_0.eot');
  src: url('/assets/fonts/avenir/3799F6_0_0.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/avenir/3799F6_0_0.woff2') format('woff2'), url('/assets/fonts/avenir/3799F6_0_0.woff') format('woff'), url('/assets/fonts/avenir/3799F6_0_0.ttf') format('truetype');
}


@font-face {
  font-family: 'AvenirLTPro-Book';
  src: url('/assets/fonts/avenir/3799F6_1_0.eot');
  src: url('/assets/fonts/avenir/3799F6_1_0.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/avenir/3799F6_1_0.woff2') format('woff2'), url('/assets/fonts/avenir/3799F6_1_0.woff') format('woff'), url('/assets/fonts/avenir/3799F6_1_0.ttf') format('truetype');
}


@font-face {
  font-family: 'AvenirLTPro-Medium';
  src: url('/assets/fonts/avenir/3799F6_2_0.eot');
  src: url('/assets/fonts/avenir/3799F6_2_0.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/avenir/3799F6_2_0.woff2') format('woff2'), url('/assets/fonts/avenir/3799F6_2_0.woff') format('woff'), url('/assets/fonts/avenir/3799F6_2_0.ttf') format('truetype');
}


@font-face {
  font-family: 'AvenirLTPro-Heavy';
  src: url('/assets/fonts/avenir/3799F6_3_0.eot');
  src: url('/assets/fonts/avenir/3799F6_3_0.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/avenir/3799F6_3_0.woff2') format('woff2'), url('/assets/fonts/avenir/3799F6_3_0.woff') format('woff'), url('/assets/fonts/avenir/3799F6_3_0.ttf') format('truetype');
}


@font-face {
  font-family: 'AvenirLTPro-Black';
  src: url('/assets/fonts/avenir/3799F6_4_0.eot');
  src: url('/assets/fonts/avenir/3799F6_4_0.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/avenir/3799F6_4_0.woff2') format('woff2'), url('/assets/fonts/avenir/3799F6_4_0.woff') format('woff'), url('/assets/fonts/avenir/3799F6_4_0.ttf') format('truetype');
}
