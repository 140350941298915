@import "includes";

.auth-page {
  display: flex;
  flex-direction: column;

  min-height: 100vh;

  padding: 0 32px;

  @include desktop {
    width: 340px;
    padding: 0;
    margin: 0 auto;
  }

  .heading {
    font-family: var(--default-font-family-heavy);
    font-size: 24px;
    line-height: 33px;

    text-align: center;
    margin-bottom: 8px;
  }

  .hint {
    text-align: center;
    margin-bottom: 25px;
    line-height: 19px;
  }

  .link-text {
    cursor: pointer;
  }

  vc-page-header-logo {
    margin-top: 67px;
    margin-bottom: 40px;

    @include desktop {
      margin-top: 52px;
      margin-bottom: 32px;
    }
  }

  vc-link-switch {
    margin-bottom: 29px;
  }

  vc-form-field {
    margin-bottom: 17px;

    &.margin-l {
      margin-bottom: 16px;
    }

    label {
      .detail {
        color: rgba(55, 59, 63, 0.2);

        @include dark {
          color: var(--color-gray3);
        }
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;

    &>*:not(:first-child) {
      margin-bottom: 12px;
    }

    .or {
      margin: 16px 0;
      text-align: center;
    }
  }

  .bottom {
    margin-top: auto;
    margin-bottom: 58px;

    padding-top: 22px;

    border-top: 2px solid var(--color-gray7);

    text-align: center;

    @include dark {
      border-top-color: var(--color-gray6);
    }
  }

  .error-message {
    color: var(--color-red1);

    padding-bottom: 16px;

    &.icon-warning {
      display: flex;
      align-items: center;

      &:before {
        content: "warning";
        font-family: var(--default-icon-font-family);
        font-size: 24px;

        margin-right: 8px;
      }
    }
  }
}
