.separator {
  border-bottom: 1px solid rgba(55, 59, 63, 0.2);
}

.cursor-pointer {
  cursor: pointer;
}

.verum-section {
  @include border-radius;
  background-color: #f9f9f9;
  @include dark {
    background-color: #52595f !important;
  }
}

.verum-label {
  border-radius: 11.5px;
  background-color: var(--color-blue1);
  width: max-content !important;
  font-size: 10px;
  font-weight: 500;
  color: white;
  word-break: break-word;
  letter-spacing: 0;
  line-height: 14px;
}

.tooltip-container {
  position: relative;
  display: inline-block;
  flex-basis: content;
}

.tooltip {
  position: absolute;
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  background-color: #333;
  color: #fff;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0.2s;
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #333;
}

.tooltip-container:hover .tooltip {
  opacity: 1;
  visibility: visible;
}

.border-gray-3 {
  border: 1px solid var(--color-gray3);
}