// Breakpoints
$breakpoints: (
  "sm": "screen and (min-width: 576px)",
  "md": "screen and (min-width: 768px)",
  "lg": "screen and (min-width: 992px)",
  "xlg": "screen and (min-width: 1440px)",
);

// generate display classes of the type display-*
$displays: (
  "flex": flex,
  "inline": inline,
  "block": block,
  "inline-block": inline-block,
);

@each $display-name, $display in $displays {
  .display-#{$display-name} {
    display: #{$display};
  }
}

// generate classes for flex e.g justify-content-* , align-items-*, align-content-*
$positions: (
  "center": center,
  "space-between": space-between,
  "space-around": space-around,
  "start": start,
  "end": end,
);

@each $position-name, $position in $positions {
  .justify-content-#{$position-name} {
    justify-content: #{$position};
  }
  .align-items-#{$position-name} {
    align-items: #{$position};
  }
  .align-content-#{$position-name} {
    align-content: #{$position};
  }
}

.page-content-with-side-nav {
  margin-left: 270px;
  margin-top: 60px;

  @include mobile {
    margin-left: 0px;
  }
}

// generate classes for margin and padding m-* p-* mb-* pb-* pt-* p
$defaultSpacer: 1rem;
$spacers: (
  0: 0,
  1: $defaultSpacer * 0.25,
  2: $defaultSpacer * 0.5,
  3: $defaultSpacer,
  4: $defaultSpacer * 1.5,
  5: $defaultSpacer * 3,
  auto: "auto",
);

@each $spacer-name, $spacer in $spacers {
  .m-#{$spacer-name} {
    margin: #{$spacer};
  }
  .mb-#{$spacer-name} {
    margin-bottom: #{$spacer};
  }
  .mt-#{$spacer-name} {
    margin-top: #{$spacer};
  }
  .ml-#{$spacer-name} {
    margin-left: #{$spacer};
  }
  .mr-#{$spacer-name} {
    margin-right: #{$spacer};
  }
  .mx-#{$spacer-name} {
    margin-left: #{$spacer};
    margin-right: #{$spacer};
  }
  .my-#{$spacer-name} {
    margin-top: #{$spacer};
    margin-bottom: #{$spacer};
  }
  .p-#{$spacer-name} {
    padding: #{$spacer};
  }
  .pb-#{$spacer-name} {
    padding-bottom: #{$spacer};
  }
  .pt-#{$spacer-name} {
    padding-top: #{$spacer};
  }
  .pl-#{$spacer-name} {
    padding-left: #{$spacer};
  }
  .pr-#{$spacer-name} {
    padding-right: #{$spacer};
  }
  .px-#{$spacer-name} {
    padding-left: #{$spacer};
    padding-right: #{$spacer};
  }
  .py-#{$spacer-name} {
    padding-top: #{$spacer};
    padding-bottom: #{$spacer};
  }
  @each $breakpoint-name, $breakpoint in $breakpoints {
    @media #{$breakpoint} {
      .m-#{$breakpoint-name}-#{$spacer-name} {
        margin: #{$spacer};
      }
      .mb-#{$breakpoint-name}-#{$spacer-name} {
        margin-bottom: #{$spacer};
      }
      .mt-#{$breakpoint-name}-#{$spacer-name} {
        margin-top: #{$spacer};
      }
      .ml-#{$breakpoint-name}-#{$spacer-name} {
        margin-left: #{$spacer};
      }
      .mr-#{$breakpoint-name}-#{$spacer-name} {
        margin-right: #{$spacer};
      }
      .mx-#{$breakpoint-name}-#{$spacer-name} {
        margin-left: #{$spacer};
        margin-right: #{$spacer};
      }
      .my-#{$breakpoint-name}-#{$spacer-name} {
        margin-top: #{$spacer};
        margin-bottom: #{$spacer};
      }
      .p-#{$breakpoint-name}-#{$spacer-name} {
        padding: #{$spacer};
      }
      .pb-#{$breakpoint-name}-#{$spacer-name} {
        padding-bottom: #{$spacer};
      }
      .pt-#{$breakpoint-name}-#{$spacer-name} {
        padding-top: #{$spacer};
      }
      .pl-#{$breakpoint-name}-#{$spacer-name} {
        padding-left: #{$spacer};
      }
      .pr-#{$breakpoint-name}-#{$spacer-name} {
        padding-right: #{$spacer};
      }
      .px-#{$breakpoint-name}-#{$spacer-name} {
        padding-left: #{$spacer};
        padding-right: #{$spacer};
      }
      .py-#{$breakpoint-name}-#{$spacer-name} {
        padding-top: #{$spacer};
        padding-bottom: #{$spacer};
      }
    }
  }
}

// responsiveness related layout classes

.is-visible-mobile-only {
  @include desktop {
    display: none;
  }
  @include mobile {
    display: block;
  }
}
.is-visible-desktop-only {
  @include desktop {
    display: block;
  }
  @include mobile {
    display: none;
  }
}

// generate classes for width and height
$widths: (
  "100": 100%,
  "75": 75%,
  "60": 60%,
  "50": 50%,
  "40": 40%,
  "25": 25%,
);

$heights: (
  "100": 100%,
  "75": 75%,
  "60": 60%,
  "50": 50%,
  "40": 40%,
  "25": 25%,
);

// Generate width classes
@each $width-name, $width in $widths {
  .w-#{$width-name} {
    width: $width;
  }

  @each $breakpoint-name, $breakpoint in $breakpoints {
    @media #{$breakpoint} {
      .w-#{$breakpoint-name}-#{$width-name} {
        width: $width;
      }
    }
  }
}

.w-min-content {
  width: min-content;
}

.w-max-content {
  width: max-content;
}
// Generate height classes
@each $height-name, $height in $heights {
  .h-#{$height-name} {
    height: $height;
  }

  @each $breakpoint-name, $breakpoint in $breakpoints {
    @media #{$breakpoint} {
      .h-#{$breakpoint-name}-#{$height-name} {
        height: $height;
      }
    }
  }
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}