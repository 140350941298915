@import '~material-design-icons/iconfont/material-icons.css';
@import "~@angular/cdk/overlay-prebuilt.css";

@import "colors.global";
@import "fonts.global";
@import "modules/auth/auth.global.scss";
@import "includes";

:root {
  --default-font-family-light: "AvenirLTPro-Light";
  --default-font-family-medium: "AvenirLTPro-Medium";
  --default-font-family-heavy: "AvenirLTPro-Heavy";

  --default-font-size: 14px;
  --default-color: var(--color-gray1);

  --default-icon-font-family: "Material Icons";

  @include dark {
    --default-color: var(--color-white);
  }
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;

  font-family: var(--default-font-family-light);
  font-size: var(--default-font-size);
  color: var(--default-color);

  background: var(--color-white);

  @include dark {
    background: var(--color-gray1);
  }
}

body.no-scroll {
  touch-action: none;
  -ms-touch-action: none;
  overflow: hidden;
  position: relative;
}

a, a:active, a:hover, a:visited {
  color: var(--color-green1);
  font-family: var(--default-font-family-medium);

  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

// Global typography classes
@each $size-name, $size in $font-sizes {
  @each $weight-name, $weight in $font-weights {
    .text#{$size-name}#{$weight-name} {
      @extend %text#{$size-name}#{$weight-name};
    }

    @each $color-name, $color in $font-colors {
      .text#{$size-name}#{$weight-name}-#{$color-name} {
        @extend %text#{$size-name}#{$weight-name}-#{$color-name};
      }
    }
  }
}
