@mixin dark {
  @media (prefers-color-scheme: dark) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 768px) {
    @content;
  }
};

@mixin mobile {
  @media (max-width: 767px) {
    @content;
  }
};

@mixin border-radius {
  border-radius: 3px;
}

@mixin box-shadow {
  box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.1);
}

