@import "includes";

:root {
  --color-white: #fff;

  --color-green1: #1CC29F;
  --color-green1-rgb: 28, 194, 159;
  --color-green2: #ACE4D6;
  --color-green3: #DEF4EF;


  --color-gray1: #373B3F;
  --color-gray2: #51595e;
  --color-gray3: #DCDCDC;
  --color-gray3-rgb: 220, 220, 220;
  --color-gray4: #EFFAF7;
  --color-gray5: #4D5C5C;
  --color-gray6: rgba(255, 255, 255, 0.2);
  --color-gray7: rgba(0, 0, 0, 0.1);
  --color-gray8: rgba(55,59,63,0.4);

  --color-purple1: #976DFF;
  --color-purple2: #B4A0E5;

  --color-red1: #DB504A;
  --color-red2: #F07E7A;

  --color-blue1: #60AFFF;
  --color-blue2: #A2CBF4;
  --color-orange1: #F5853F;
  --color-orange2: #FBBA72;
}

