
$font-sizes: (
  "-12": 12px,
  "-14": 14px,
  "-16": 16px,
  "-18": 18px,
  "-22": 22px,
  "-24": 24px,
);

// $font-size-line-heights: (
//   "-12": 12px,
//   "-14": 14px,
//   "-16": 16px,
// );

$font-weights: (
  "-light": "AvenirLTPro-Light",
  "-medium": "AvenirLTPro-Medium",
  "-heavy": "AvenirLTPro-Heavy",
);

$font-colors: (
  "white": "--color-white",
  "gray1": "--color-gray1",
  "gray2": "--color-gray2",
  "gray3": "--color-gray3",
  "gray8": "--color-gray8",
  "green1": "--color-green1",
);

@each $size-name, $size in $font-sizes {
  @each $weight-name, $weight in $font-weights {
    // @debug text#{$size-name}#{$weight-name};
    %text#{$size-name}#{$weight-name} {
      font-size: $size;
      font-family: $weight;
    }

    // colors
    @each $color-name, $color in $font-colors {
      // @debug "Processing size: #{$size-name}, weight: #{$weight-name}, color: #{$color-name}";
      %text#{$size-name}#{$weight-name}-#{$color-name} {
        font-size: $size;
        font-family: $weight;
        color: var(#{$color});
      }

      %border#{$color-name} {
        border: 1px solid $color;
      }
    }
  }
}

// set text alignment
$alignments: (
  "-left": "left",
  "-right": "right",
  "-center": "center",
);

@each $alignments, $alignment in $alignments {
  .text-#{$alignment} {
    text-align: #{$alignment};
  }
}

.text-lowercase {
  text-transform: lowercase;
}